import React, {useState, useEffect}from 'react'
import { Breadcrumb, Table, Button, Typography, Divider} from 'antd';
import requester from '../../utils/requester'
import querystring from 'querystring';
import { Link } from 'react-router-dom';
import {useParams} from 'react-router'

const {Title} = Typography

const AppConfigs = () => {
    const [data, setData] = useState([])
    
    useState(() => {

    }, [])

    const {appid, appname} = useParams()
   
    useEffect(() => {
        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            // eslint-disable-next-line
            width: 50,
            // render: (text) => <Link to={`/confighub/app/config/${text}`}>{text}</Link>
        },
        {
            title: '版本',
            dataIndex: 'version',
            key: 'id',
        },
        {
            title: '下载链接',
            dataIndex: 'download_url',
            key: 'download_url',
        },
        {
            title: '标记',
            dataIndex: 'tags',
            key: 'tags',
        },
        {
            title: '最近修改时间',
            dataIndex: 'mtime',
            key: 'mtime',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'id',
        },
        {
            title: '操作',
            dataIndex: 'id',
            render: (text, record) => <Link to={`/confighub/${record.app.name}/${appid}/config/${text}`}>修改</Link>
        }
    ];

    const loadData = (params) => {
        requester.get(`app/configs/${appid}?` + querystring.stringify(params)).then( (response) => {
            if (response.status === 200) {
                setData(response.data.data)
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }} 
            items={[
                {
                    title: "配置中心"
                },
                {
                    title: appname
                },
                {
                    title: "配置列表"
                }
            ]}/>
            <Typography>
                <Title level={2}>{appname}的所有配置</Title>
            </Typography>
            <Link to={`/confighub/${appname}/${appid}/config`}><Button type="primary">添加版本配置</Button></Link>
            <Divider></Divider>
            <Table rowKey={(record) => record.id} columns={columns} dataSource={data}/>
        </div>  
    )
}

export default AppConfigs;