import React, { useState, useEffect } from "react";
import { Breadcrumb, Table, Button } from "antd";
import requester from "../../utils/requester";
import { Link } from "react-router-dom";
import querystring from 'querystring';


const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        // eslint-disable-next-line
        width: 50,
        render: (text) => <Link to={`/version/${text}`}>{text}</Link>
    },
    {
        title: 'App',
        dataIndex: 'app_name',
        key: 'id',
    },
    {
        title: 'version',
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: '是否显示',
        dataIndex: 'is_show',
        key: 'is_show',
    },
    {
        title: 'platform',
        dataIndex: 'platform',
        key: 'platform',
    }
];

const Version = () => {
    const loadData = (params) => {
        requester.get('/versions?' + querystring.stringify(params)).then((response) => {
            if (response.status === 200) {
                setData(response.data.data.data)
                setPagination({
                    page: response.data.data.current_page,
                    current: response.data.data.current_page,
                    total: response.data.data.total
                })
            }
        })
    }

    const onPageChange = (page, pageSize) => {
        loadData({ page: page, pageSize: pageSize })
    }

    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
            defaultCurrent: 1,
            defaultPageSize: 20,
            pageSize: 20,
            page: 1,
            total: 0,
            current: 1,
            onChange: onPageChange
    })

    useEffect(() => {
        console.log("here is version")
        loadData({page:1, pageSize: 20})
    }, [])

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>版本列表</Breadcrumb.Item>
            </Breadcrumb>
            <Link to="/version/create"><Button type="primary">新增版本</Button></Link>
            <Table columns={columns} dataSource={data} pagination={pagination}/>
        </div>
    )
}

export default Version